body {
  margin: 0;
  overflow-x: hidden; 
}

.supplements { 
  font-size: 2rem;
  margin: 0px;
}

.supplements-page { 
    display: flex;
    flex-direction: row;
    width: calc(100vw - 11vw); 
    margin-left: 13vw;    
    height: 100%;
    justify-content: flex-start;
    background-color: white;
    /* background-color: rgb(231, 244, 240); */
}

.supplement-body { 
  display: flex; 
  flex-direction: column; 
  align-items: center;
  width: 100%;
  margin: 20px;
}

.supplement-tiles ul li{ 
  list-style-type: none;
  margin: 10px;
  color: rgb(0, 0, 0);
}

.supplement-tiles ul {
  padding: 0px;
  display: flex; 
  flex-direction: row;
  justify-content: space-evenly;
}

.supplement-tiles { 
  margin: 10px;
  border-radius: 10px;
  width: 75%;
  background-color: white;
  box-shadow: 0 0 6px 2px rgb(193, 219, 218);
}

.supp-source {
  background-color: #e0e0e0;
  padding: 4px 8px;
  border-radius: 10px;
  font-size: 12px;
  color: #666;
  margin-left: 15px;
}

/* .supplements-header {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #888;
  margin-bottom: 10px;
}

.supplements-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.supplement-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #e0f7f4;
  margin-bottom: 10px;
  background-color: #f9fdfd;
}

.supplement-icon {
  font-size: 24px;
  margin-right: 10px;
}

.supplement-name {
  flex: 2;
  font-size: 16px;
  font-weight: 500;
}

.supplement-dosage {
  flex: 1;
  text-align: center;
  font-size: 14px;
  color: #333;
}

.supplement-source {
  flex: 1.5;
  display: flex;
  justify-content: flex-end;
  gap: 5px;
}

.source-tag {
  background-color: #e0e0e0;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  color: #666;
} */