.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*, p {
  color: black;
  text-decoration: none;
}

/* background image used for entire site */
 body {
  background-color: white;
  background-size: cover;
  background-position: center center;
  margin: 0;
  height: 100vh;
} 

/* media queries for setting relative font size for entire page */
@media (max-width: 1240px) {
  html {
    font-size: 1rem
  }
}
@media (max-width: 992px) {
  html {
    font-size: 0.90rem
  }
}
@media (max-width: 744px) {
  html {
    font-size: 0.80rem
  }
}


/* css for dashboard page

.dashboard-page { 
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 100vh;
  justify-content: flex-start;
}

.overview { 
  font-size: 2rem;
}

.main-display { 
  display: flex; 
  flex-direction: column;
  width: 85vw;
  /* margin: 15px; */
  /* padding: 30px; */
/* } */

/* .display-header { 
  border-bottom: 2px solid black;
} */

/* .display-body { 
  display: flex; 
  flex-direction: row;
  justify-content: space-evenly;
  height: 100vh;
} */

/* .display-col-left { 
  display: flex; 
  flex-direction: column; 
  align-items: center;
  margin: 20px;
  width: 50%;
} */

/* .display-col-right { 
  display: flex; 
  flex-direction: column; 
  align-items: center;
  margin: 20px;
  width: 50%;
} */

/* .display-tiles { 
  outline: 5px solid rgb(247, 247, 247);
  margin: 20px;
  text-align: left;
  border-radius: 5px;
  width: 100%;
  padding: 10px;
  /* box-shadow: 10px 10px 10px rgb(98, 195, 165); */
/* } */

/* .display-tiles:hover { 
  cursor: pointer;
}

#supplements { 
  height: 100%
}

#check-in { 
  height: 10%
}

#genome { 
  height: 70%; 
}

#blood-results { 
  height: 70%; 
}

.blood-info { 
  border-radius: 5px;
  outline: 2.5px solid rgb(219, 219, 219);
  margin: 15px;
  padding: 15px;
}

.gauge-img { 
  display: block;
  height: 200px;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
}

.genome-img { 
  height: 200px;
  width: 200px;
  margin-right: 15px;
}

.genome-info { 
  border-radius: 5px;
  outline: 2.5px solid rgb(219, 219, 219);
  margin: 15px;
  padding: 15px;
  display: flex; 
  flex-direction: row;
  justify-content: space-evenly;
}

/* css for insight page */

/* .insights-page { 
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 100vh;
  justify-content: flex-start;
}

.insights { 
  font-size: 2rem;
}

.tab-section { 
  /* outline: 2px solid black; */
  /* border-radius: 10px; */
  /* padding: 10px; */
/* } */

/* .tab-section ul {
  list-style-type: none;
  margin: 0px; 
  padding: 0px;
} */

/* .tab-section ul li { 
  display: inline; 
  color: black; 
  text-align: center; 
  margin-right: 30px
  /* background-color: rgb(177, 177, 177); */
  /* padding: 10px;
  padding-right: 15px;
  padding-left: 15px; */
/* } */

/* .tab-section ul li:hover { 
  color: black; 
  cursor: pointer; 
  background-color: white;
} */

/* .tab-content { 
  display: none;
} */

/* .show_content { 
  display: block;
} */

/* .tab-display { 
  margin: 10px;
  margin-bottom: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
} */

/* .display-content { 
  /* background-color: rgb(177, 177, 177); */
  /* height: 60vh;
  outline: 2px solid black;
  border-radius: 10px;
  padding: 10px;
} */ 

/* h1 { 
  margin-top: 0px;
} */
.mainContainer {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  font-size: 59px;
  font-weight: bolder;
  align-items: center;
  justify-content: center;
}

.resultContainer,
.historyItem {
  flex-direction: row;
  display: flex;
  width: 400px;
  align-items: center;
  justify-content: space-between;
}

.historyContainer {
  flex-direction: column;
  display: flex;
  height: 200px;
  align-items: center;
  flex-grow: 5;
  justify-content: flex-start;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 260px;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.inputContainer > .errorLabel {
  color: red;
  font-size: 12px;
}

.inputBox {
  height: 48px;
  width: 400px;
  font-size: large;
  border-radius: 8px;
  border: 1px solid grey;
  padding-left: 8px;
}

.loginBtn {
    border: none;
    background: black;
    color: white;
    padding: 12px 24px;
    margin: 8px;
    font-size: 24px;
    border-radius: 8px;
    cursor: pointer;
}

.logo {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.logo-img-big {
  height: 7.5vh;
  width: auto;
  max-width: 100%;
  margin-top: 0.5vw;
}

.logo-img:hover {
  cursor: pointer;
}
