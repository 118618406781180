@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');

.supplements-list-container { 
  margin-top: 15px;
  text-align: left;
  border-radius: 10px;
  width: 100%;
  padding: 15px;
  background-color: white;
  box-shadow: 0 0 6px 2px rgb(193, 219, 218);
}

.supplements-list-headers { 
  display: flex;
  flex-direction: row; 

}

.supplements-info { 
  list-style: none;
  padding: 10px;
  font-family: "Inter", sans-serif;
  font-weight: 350;
  font-style: normal; 
}

.supplement-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
  border-bottom: 1px solid #eee;
}

.supplement-item .supplement-name {
  flex: 2; 
}

.supplement-item .supplement-dosage {
  flex: 1; 
  text-align: right;
  margin-right: 10px;
}

.supplement-item .source-grey .source-green{
  flex: 1.5;
  display: flex;
  justify-content: flex-end;
}

.source-grey span {
  background-color: #e0e0e0;
  padding: 4px 8px;
  border-radius: 10px;
  font-size: 12px;
  color: #666;
  margin-left: 7px;
}

.source-green span {
  background-color: #20AA81;
  opacity: 0.5;
  padding: 4px 8px;
  border-radius: 10px;
  font-size: 12px;
  /*color: #666;*/
  color: #000000;
  margin-left: 7px;
}

.logo-img-small {
  height: 3.2vh;
  width: auto;
  max-width: 100%;
  margin-top: 0.5vw;
  margin-right: 13px;
}

.logo-img:hover {
  cursor: pointer;
}
