@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');

.genome-insights-container { 
    margin-top: 21px;
    margin-left: 14px;
    text-align: left;
    border-radius: 10px;
    width: 100%;
    padding: 15px;
    background-color: white;
    box-shadow: 0 0 6px 2px rgb(193, 219, 218);
  }
  
.genome-insight-card { 
    display: flex; 
    flex-direction: row;
}
  
.genome-img { 
    display: block;
    height: 180px;
    width: 180px;
    margin: 10px;
}
