@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');

.weekly-check-in-container {
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .check-in-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-family: "Montserrat", sans-serif;
    font-size: 23px;
    font-weight: 500;
    padding: 15px 50px;
    border: 1px solid #ffffff;
    border-radius: 10px;
    background: #ffffff;
    box-shadow: 0 0 6px 2px rgb(193, 219, 218);
    cursor: pointer;
  }
  
  .check-in-button:hover {
    border: 1px solid #f7f9fc;
    background: #f7f9fc; 
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15), 0px 2px 6px rgba(0, 0, 0, 0.08);
  }
  
  .check-in-button:disabled {
    cursor: not-allowed;
    background: #e0e0e0; 
    box-shadow: none;
  }
  
  .check-in-button .icon {
    font-size: 18px;
  }
  
  .check-in-button.completed {
    background: #e8f5e9; 
    color: #388e3c; 
    border-color: #c8e6c9;
  }
