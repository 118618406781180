body {
  margin: 0;
  overflow-x: hidden; 
}

.insights-page { 
  display: flex;
  flex-direction: row;
  width: 88%;
  margin-left: 13vw;
  height: 100vh;
  justify-content: flex-start;
  background-color: white;
  /* background-color: rgb(231, 244, 240); */
}

.main-display { 
  width: calc(100vw - 11vw);
  margin: 15px;
  padding: 30px;
}

/* .insights { 
  font-size: 2rem;
  margin: 0px;
} */

.tab-section { 
  /* outline: 2px solid black; */
  border-radius: 10px;
  padding: 10px;
}

/* .tab-display { 
  margin-left: auto;
  margin-right: auto;
} */

.tab-section ul {
  list-style-type: none;
  margin: 0px; 
  padding: 0px;
  text-align: center;
}

.tab-section ul li { 
  display: inline; 
  color: black; 
  text-align: center; 
  /* margin-right: 15px; */
  background-color: rgb(232, 232, 232);
  /* outline: 1px solid rgb(179, 179, 179); */
  border-radius: 5px;
  padding: 10px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: 13px;
}

.tab-section ul li:hover { 
  color: black; 
  cursor: pointer; 
  background-color: rgb(82, 129, 115);
  color: white;
}

.tab-content { 
  display: none;
}

.show_content { 
  display: block;
}

.tab-display { 
  margin: 10px;
  margin-bottom: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.display-content { 
  box-shadow: 0 0 10px 5px rgb(193, 219, 218);
  background-color: rgb(255, 255, 255);
  height: 60vh;
  outline: 1px solid rgb(179, 179, 179);
  border-radius: 10px;
  padding: 10px;
}

h1 { 
  margin-top: 0px;
}
