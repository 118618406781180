/* .sidebar-page { 
  height: 100%;
} */

@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');

.sidemenu { 
  position: fixed; 
  top: 0;          
  left: 0;         
  width: 12vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: white;
  border-right: 2.5px rgb(228, 228, 228) solid; 
  z-index: 10; 
  font-family: "Montserrat", sans-serif;
  font-weight: 650;
}
  
  .logo {
    display: flex; 
    flex-direction: row;
    align-items: center;
    
    /* margin-bottom: 60px; */
  }
  
  .logo-img { 
    height: 4vh;   
    width: auto;
    max-width: 100%;
    margin-left: 0.0vw;
  }
  
  .logo-img:hover { 
    cursor: pointer;
  }
  
  .logo-text, .logo-text-active {
    text-align: left;
    font-size: 1.2rem;
    margin-left: 0.4vw;
    color: rgb(0, 0, 0);
  }
  
  .logo-text-active:hover {
    cursor: pointer;
  }

  .sidebar-text, .sidebar-text-active { 
    color: rgb(0, 0, 0);
    font-size: 17px;
  }
  
  /* .side-menu { 
    margin-bottom: 550px;
  } */

  .side-menu ul li {
    list-style-type: none;
    margin: 10px;
    color: rgb(0, 0, 0);
  }
  
  .side-menu ul {
    padding: 0px;
    display: flex; 
    flex-direction: column;
    justify-content: space-evenly;
  }
  
  .sidebar-list-active ul:hover {
    cursor: pointer;
  }


  
  
