@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');

.blood-insights-container { 
  margin-bottom: 15px;
  margin-top: 5px;
  margin-right: -15px;
  text-align: left;
  border-radius: 15px;
  width: 100%;
  padding: 15px;
  background-color: white;
  box-shadow: 0 0 6px 2px rgb(193, 219, 218);
}

.blood-insight-card { 
  display: flex; 
  flex-direction: row;
}

.gauge-img { 
  display: block;
  height: 200px;
  width: 210px;
  margin: 10px;
  margin-right: 50px;
  /*-37*/
  margin-bottom: -25px;
  /*0*/
  margin-top: -15px;
}

.card-button { 
  background-color: white;
  border: none;
  font-size: 50px;
  color: rgb(213, 213, 213);
}

.card-button:hover { 
  cursor: pointer;
}

.full-insights { 
  text-align: center;
}

.more-insights { 
  background-color: #e0e0e0;
  padding: 7px 15px;
  border-radius: 20px;
  font-size: 14px;
  color: #000000;
}

.side-menu ul li {
  list-style-type: none;
  margin: 0px;
  color: rgb(0, 0, 0);
}

.side-menu ul {
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.sidebar-list-active ul:hover {
  cursor: pointer;
}

.img-txt
{
    position: relative;
}

.txt-img
{
    position: absolute;
    margin-left: 84px;
    margin-top: 0px;
    margin-bottom: 200px;
    padding: 0px;
}

.blood-level-normal {
    margin-left: 78px;
    padding: 0px;
    display: flex;
    color: #219653;
    /*color: #636363; = grey*/
    font-size: 21px;
    font-weight: 780;
    
    /*color: #e0e0e0;*/
}

.blood-level-low {
    margin-left: 89.5px;
    margin-top: 18px;
    padding: 0px;
    display: flex;
    color: #B53737;
    font-size: 26px;
    font-weight: 750;
    /*color: #e0e0e0;*/
}

.blood-level-high {
    margin-left: 91px;
    margin-top: 18px;
    padding: 0px;
    display: flex;
    color: #B53737;
    font-size: 24px;
    font-weight: 750;
    /*color: #e0e0e0;*/
}

.results-button {
  display: absolute;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-family: "Montserrat", sans-serif;
  font-size: 17px;
  font-weight: 500;
  margin-top: 20px;
  margin-bottom: 40px;
  margin-right: 10px;
  margin-left: 24.5px;
  padding: 15px 20px;
  border: 1px solid #ffffff;
  border-radius: 10px;
  background: #ffffff;
  /*opacity: 0.5;*/
  /*background: #e8f5e9;*/
  /*background: #20AA81;*/
  /*color: #388e3c; */
  box-shadow: 0 0 6px 2px rgb(193, 219, 218);
  cursor: pointer;
}

.results-button:disabled {
  cursor: not-allowed;
  background: #e0e0e0;
  box-shadow: none;
}

