@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');

.dashboard-container { 
  display: flex;
  flex-direction: row;
  width: 87%;
  height: 100%;
  justify-content: flex-start;
  background-color: white;
  margin-left: 13vw;
}

.main-content { 
  display: flex; 
  flex-direction: column;
  width: calc(100vw - 11vw);
  margin: 15px;
  padding: 30px;
}

.widget-content { 
  display: flex; 
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  width: 100%
}

.left-content { 
  display: flex; 
  flex-direction: column; 
  align-items: flex-start;
  width: 47%;
  margin-left: -21px;
}

.right-content { 
  display: flex; 
  flex-direction: column; 
  align-items: center;
  width: 52%; /*47%*/
}

/* styling for all files */
h1 { 
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 45px;
}

h2 { 
  margin-top: 0px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-style: normal;
}

h3 { 
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-style: normal;
}

h4 { 
  font-family: "Inter", sans-serif;
  font-weight: 650;
  font-style: normal;
}

p { 
  font-family: "Inter", sans-serif;
  font-weight: 350;
  font-style: normal;
}

.profile-info-container {
    margin-top: 21px;
    margin-left: 14px;
    margin-bottom: 32px;
    text-align: left;
    border-radius: 10px;
    width: 100%;
    padding: 15px;
    background-color: white;
    box-shadow: 0 0 6px 2px rgb(193, 219, 218);
  }

.profile-info-card {
    display: flex;
    flex-direction: row;
}

.display-info-button {
  display: absolute;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-weight: 500;
  margin-top: 20px;
  margin-bottom: 40px;
  margin-right: 10px;
  margin-left: 24.5px;
  padding: 10px 10px;
  border: 1px solid #ffffff;
  border-radius: 10px;
  background: #ffffff;
  /*opacity: 0.5;*/
  /*background: #e8f5e9;*/
  /*background: #20AA81;*/
  /*color: #388e3c; */
  box-shadow: 0 0 6px 2px rgb(193, 219, 218);
  cursor: pointer;
}

.display-info-button:disabled {
  cursor: not-allowed;
  background: #e0e0e0;
  box-shadow: none;
}
